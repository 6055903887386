import * as React from "react";
import Layout from "../../../Components/Layout";
import { IFilter, IMe } from "../../../types";
import useInformesRouteParams, {
  InformeRouteType,
} from "../hooks/useInformesRouteParams";
import useHasPermissions from "../hooks/useHasPermissions";
import useLoader from "../hooks/useLoader";
import InformesTableContainer from "../modules/informes-table/containers/InformesTable";
// import { AuditData } from "../../../ApiClients/DummyData";
import { InformeT } from "../types";
import { useHistory } from "react-router-dom";
import OrderService from '../../../ApiClients/OrderService';

export interface Props {
  history: any;
  match: any;
  me?: IMe;
  version: string;
  filters: IFilter;
  handleCheckFilter: (centroTrabajoId: number) => void;
  handleChangeFilter: (id: string, filters: any) => void;
}

const InformesRoute: React.FC<Props> = ({
  history,
  match,
  version,
  me,
  ...props
}) => {
  const { informeRouteType, centrotrabajoId } = useInformesRouteParams({});

  // Checks for permissions accessing the page
  const { pageAccessDenied } = useHasPermissions({
    me,
    centrotrabajoId,
    permissions: [5, 20, 50, 100],
  });

  // This, once it has access permissions, checks for edit permissions
  const { hasPermissions: canCreateInforme } = useHasPermissions({
    me,
    centrotrabajoId,
    permissions: [50, 100],
  });

  const title = React.useMemo(
    () => (
      <div className="App-title-webPage">
        {`Informes ${
          informeRouteType === InformeRouteType.VISITAS
            ? `visitas`
            : `auditorías`
        }`}
      </div>
    ),
    [informeRouteType]
  );

  return (
    <div className="App-basic">
      <Layout
        history={history}
        currentPage={1}
        allowDrawer={true}
        match={match}
        version={version}
        me={me}
        showEmpresa
        showLateralMenu
        loading={!me?true:false}
      />

      <div className="App-body-webBar">
        {me
          ? pageAccessDenied || (
              <InformesQuery
                centrotrabajoId={centrotrabajoId}
                title={title}
                informeType={informeRouteType}
                userIdName={me.userIdName}
                canCreateInforme={canCreateInforme}
                {...props}
              />
            )
          : /*`Fallo en la carga del perfil, volver a intentar`*/null}
      </div>
    </div>
  );
};

type InformesQueryProps = Pick<
  Props,
  "handleCheckFilter" | "handleChangeFilter"
> & {
  title?: React.ReactNode;
  centrotrabajoId: number;
  informeType: InformeRouteType;
  userIdName: IMe["userIdName"];
  canCreateInforme?: boolean;
};

const InformesQuery = ({
  title,
  centrotrabajoId,
  handleCheckFilter,
  informeType,
  userIdName,
  canCreateInforme,
}: InformesQueryProps) => {
  // Loading (global state)
  // Then, multiple states for each query if needed
  // E.g. Loading visitas, loading documents, etc.
  const [loading, setLoading] = React.useState(true);
  const loader = useLoader({ loading });
  const [informes, setInformes] = React.useState<InformeT[]>([]);

  const loadData = React.useCallback(async () => {
    setLoading(true);
    // TODO:OS simulates async fetch
    // try {
    //   // Query from 'centrotrabajoId' + 'informeType'
    //   const response: InformeT[] = await new Promise((resolve) => {
    //     setTimeout(() => {
    //       const informes: InformeT[] =
    //         centrotrabajoId && informeType ? [AuditData] : [];
    //       resolve(informes);
    //     }, 1000);
    //   });
    //   setInformes(response);
    // } catch (err) {
    //   console.error(err);
    // } finally {
    //   setLoading(false);
    // }
    let response: InformeT[] = await OrderService.LoadReports(centrotrabajoId, (informeType == InformeRouteType.VISITAS?1:2));
    setInformes(response);
    setLoading(false);
  }, [centrotrabajoId, informeType]);

  React.useEffect(() => {
    handleCheckFilter(centrotrabajoId);
    void loadData();
  }, [centrotrabajoId, handleCheckFilter, loadData]);

  const history = useHistory();
  
  const handleNavigateToInforme = React.useCallback(
    (informeId: number) => {
      history.push(`/${centrotrabajoId}/reports/${informeType}/${informeId}`);
    },
    [centrotrabajoId, history, informeType]
  );

  return (
    loader || (
        <div className="App-header">
          {title}
          <InformesTableContainer
            informeType={informeType}
            informes={informes}
            userIdName={userIdName}
            centrotrabajoId={centrotrabajoId}
            onNavigateToInforme={handleNavigateToInforme}
            canCreateInforme={canCreateInforme}
          />
        </div>
    )
  );
};

export default InformesRoute;
