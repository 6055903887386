type Config = {
  onSuccess?: (item:any, action:number) => void;
};

const useOnSubmitFormObservacion = ({ onSuccess }: Config) => {
  // This is the reused submit
  //   saveData=async(item:any)=>{
  //     let response:any = await OrderService.saveData(item, 2);
  //     this.loadListado();
  //     this.setState({creatingItem:false, creationItemOpen:false});
  // }
  return (item: unknown) => {
    console.log("Submitting Form Observacion 'unknown typed' item");
    console.log(item);
    if (onSuccess) {
      onSuccess(item, 1000);
    }
  };
};

export default useOnSubmitFormObservacion;
