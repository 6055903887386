import React, { Component } from 'react';
import { createStyles, withStyles, Theme } from '@material-ui/core/styles';
import Layout from './Layout';
import Empresa from './Empresa';
import Button from '@material-ui/core/Button';
import Add from '@material-ui/icons/Add';
import PaginatedTable from './Material/PaginatedControlPanelPersonal';
import {filter, includes, cloneDeep, groupBy} from 'lodash';
import OrderService from '../ApiClients/OrderService';
import { SelectorField, CheckBoxField, TextFieldInput } from './FormsWidgets';
import Toolbar from '@material-ui/core/Toolbar';
import {GetRole, GetEmpresa} from '../helpers';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FilterListIcon from '@material-ui/icons/FilterList';
import IconButton from '@material-ui/core/IconButton';
import ClearAllIcon from '@material-ui/icons/LayersClearOutlined';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Loader from './Loader';
import DatePicker from './DatePicker';
import { IMe, IPersonal, ICentroTrabajo, IFilter } from '../types';
import LoaderBeauty from './Material/activityProgress';
import CreateModal from './CreateDialog';
import Form_EPIs_03 from './Forms/EPIs_03_personal';
import LateralMenu from './LateralMenu';
import SearchField from './SearchField';
import FiltersResponsive from './Filters/Filters_responsive';
import Cert_01_info from './Forms/Cert_01_info';
import Use_Machine_01 from './Forms/Use_Machine_01';

const listadoStyles = ({
    button: {
        margin: 1,
        marginRight:15
    },
      leftIcon: {
        marginRight: 10,
    },
      rightIcon: {
        marginLeft: 1,
    },
      iconSmall: {
        fontSize: 20,
    },
    formControl: {
        margin: '1px',
        minWidth: 120,
        marginRight: 'auto',
    },
});

const fields = [
    {
        id: 'jobDescription',
        name: 'Puesto de trabajo SAP',
        required: false,
        style:1,
    },
    {
        id: 'tipo',
        name: 'Tipo trabajador',
        required: false,
        style:1,
    },
    {
        id: 'empresa',
        name: 'Empresa trabajador',
        required: false,
        style:1,
    },
];

interface IListadoProps {
    history:any;
    match:any;
    me?: IMe;
    version: string;
    filters: IFilter;
    handleChangeFilter: Function;
    handleCheckFilter: Function;
}

interface IListadoState {
    rowSelected: number;
    creationModalOpen:boolean;
    creationItemOpen: boolean;
    creationModalOpenEmail:boolean;
    modalAction:number;
    modalText:any;
    modalType:number;
    htmlBody:boolean;
    sendingEmail:boolean;
    emailResult:number;
    currentYearSelected: number|string;
    loading:boolean;
    loadingDocuments:boolean;
    documents:Array<IPersonal>;
    options?:any;
    searchValue:string;
    creatingItem:boolean;
    loadingModal: boolean;
    titleModal: string;
    createForm: boolean;
    numform: number;
    sregister?: IPersonal;
    loadingOptions:boolean;
    filter_id: string;
    modalClassName: string;
    going2attach?:any;
}

class Listado extends Component<IListadoProps, IListadoState> {
    _going2upload:any = {attachments:[]};
    constructor(props: IListadoProps) {
        super(props);
        this.state = {
            rowSelected: -1,
            //dataIncome: dataIncome,
            documents:[],
            creationModalOpen:false,
            creationItemOpen:false,
            creationModalOpenEmail:false,
            modalType: -1,
            modalAction: -1,
            modalText:'',
            sendingEmail:false,
            emailResult:-1,
            htmlBody:false,
            currentYearSelected: new Date().getFullYear(),
            loading: true,
            loadingDocuments: true,
            searchValue: '',
            creatingItem: false,
            loadingModal: false,
            titleModal: `MENSAJE`,
            createForm: false,
            numform: -1,
            loadingOptions: true,
            filter_id: "f_personal",
            modalClassName:'dialog-little',
        };
    }

    componentDidMount(){
        this.reviewPage();
        this.loadData();
    }

    componentDidUpdate(prevProps:IListadoProps){
        this.reviewPage();
        if(prevProps.me !== this.props.me || prevProps.match.params.centrotrabajoId !== this.props.match.params.centrotrabajoId)
            this.loadData();
    }

    loadData = () => {
        const {me} = this.props;

        if(!me)
            return;
        if ([5,10,11,20,50,100].indexOf(GetRole(me, this.props.match.params.centrotrabajoId)) > -1) {
            this.loadOptions();
            this.loadControlPanel();
        } else {
            this.setState({
                loading:false,
                loadingOptions: false,
                loadingDocuments: false
            })
        }
    }
    
    reviewPage = () => {
        this.props.handleCheckFilter(this.props.match.params.centrotrabajoId);
    }

    loadControlPanel = async () => {
        const centrotrabajoId = Number(this.props.match.params.centrotrabajoId);
        let response = await OrderService.LoadPanelPersonal(centrotrabajoId);
        this.setState({loadingDocuments: false, documents: response?response:[]}); // Controlar el error en un futuro
    }

    loadOptions = async () => {
        let response = await OrderService.LoadOptions(this.props.match.params.centrotrabajoId);
        this.setState({loadingOptions: false, options: response});
    }

    sendAttachFiles = async () => {
        let response = await OrderService.createImgBlob(this._going2upload, 8);
        this._going2upload = {attachments:[]};
        let p = `Fallo en el proceso, vuelva a intentarlo.`;
        if(response == 1) {
            p = `Archivo/s adjuntado/s correctamente`;
        }
        // console.log(this._going2upload)
        this.setState({
            loadingModal:false,
            modalText:p,
            modalType: 1,
            modalAction: -1,
        });
    }

    handleFilterDocuments=(documents:any, f: any)=>{
        let newDocuments = cloneDeep(documents);

        //** FILTRA POR LOS FILTROS APLICADOS */
        const keys = Object.keys(f);
        const values = Object.values(f);

        if(keys.length > 0){
            for(let a=0; a<keys.length;a++){
                newDocuments = filter(newDocuments, function(o){return o[keys[a]] == values[a]})
            }
        }

        //** FILTRA POR EL VALOR DE BÚSQUEDA */
        const searchValue = this.state.searchValue.toLowerCase();

        if(newDocuments.length > 0 && searchValue && searchValue.length > 0){
            newDocuments = filter(newDocuments, function(o:IPersonal){return includes(o.id.toLowerCase(), searchValue) || includes(o.name.toLowerCase() + ' ' + o.surname.toLowerCase(), searchValue) || includes(o.jobDescription.toLowerCase(), searchValue) });
        }

        return newDocuments;
    }

    handleSearchChange=(evt:any)=>{
        this.setState({searchValue:evt.target.value})
    }

    handleClearSearchValue=()=>{
        this.setState({searchValue:''})
    }

    handleCleanFilters = (evt:any)=>{
        evt.stopPropagation();
        this.props.handleChangeFilter(this.state.filter_id, {}, false);
    }

    onCloseCreationModal = () => {
        this.setState({ creationModalOpen: false, modalText: undefined, htmlBody:false, });
    }

    onCloseFormItemModal = () => {
        this.setState({ creationItemOpen: false });
    }

    onCloseForm = (generated:boolean) => {
        if(generated)
            this.setState({numform:-1},() => {
                this.onCloseCreationModal();
            });
        else
            this.setState({numform:-1});
    }

    /* CALL BACKEND */


    /* */

    handleCreateModal = (typeAction: number) => {
        switch(typeAction){
            case 1: // Implica adjuntar ficheros
                this.setState({loadingModal: true}, ()=>this.drawOnCanvas());
                break;
        }
        //this.onCloseCreationModal();
    }

    onCloseCreationModalEmail = () => {
        this.setState({ creationModalOpenEmail: false });
    }

    handleItemClick=(item:IPersonal, action: number)=>{
        this.props.history.push(`${window.location.hash.replace('#','')}/${item.id}`)
    }

    handleChangeFilter=(id:string, evt:any)=>{
        let newFilters = cloneDeep(this.props.filters.f_personal);
        newFilters[id] = evt.target.value;
        this.props.handleChangeFilter(this.state.filter_id, newFilters, false);
    }

    RenderFilters = (documents:Array<IPersonal>, arrayfilters:any) => {
        const grouped_jobdsc = groupBy(documents, function(register) {
            return register.jobDescription;
        });

        const merged_jobdsc = Object.keys(grouped_jobdsc).map((name:string, idx:number)=>{
            return {
                id: name,
                name: name
            }
        });

        const grouped_tipo = groupBy(documents, function(register) {
            return register.tipo;
        });

        const merged_tipo = Object.keys(grouped_tipo).map((name:string, idx:number)=>{
            return {
                id: name,
                name: name
            }
        });

        const grouped_empresa = groupBy(documents, function(register) {
            return register.empresa;
        });

        const merged_empresa = Object.keys(grouped_empresa).map((name:string, idx:number)=>{
            return {
                id: name,
                name: name
            }
        });

        const data:any = {
            jobDescription: merged_jobdsc,
            tipo: merged_tipo,
            empresa: merged_empresa
        }

        let filters = fields.map((filter:any, index:number)=>{

            return (
                <SelectorField
                    key={index}
                    name={filter.name}
                    id={filter.id}
                    deviceData={arrayfilters}
                    fieldsData={data[filter.id]?data[filter.id]:[]}
                    handleFieldChange={this.handleChangeFilter}
                    required={filter.required}
                    style={filter.style}
                    className='filter-item-200px'
                />
            );
        })

        return filters;
    }

    handleCreateItem=(item:any)=>{
        this.setState({creatingItem:true})
        this.saveData(item);
    }

    saveData=async(item:any)=>{
        let response:any = await OrderService.saveData(item, 1);
        if(response == 2){
            this.setState({
                modalText: '¡ATENCIÓN! Ya existe una medida preventiva con la misma configuración.',
                modalType: 1,
                creationModalOpen: true,
                creatingItem:false,
            });
        } else {
            this.loadControlPanel();
            this.setState({creatingItem:false, creationItemOpen:false});
        }
    }

    handleClickOptionRow=(register:IPersonal, option:number, readonly: boolean)=>{
        if(readonly) {
            this.setState({
                modalText: `¡Atención! No tiene permisos para realizar la acción.`,
                modalType: 1,
                creationModalOpen: true
            })
        } else {
            switch(option) {
                case 1: // Formulario entrega EPIs
                case 2: // Formulario entrega información
                case 3: // Formulario autorización uso maquinaria
                    this.setState({createForm: true, numform: option, sregister: register})
                    break;
                default:
                    break;
            }
        }
    }

    handleAttachFiles=(evt:any, personal:IPersonal)=>{
        const afiles = evt.target.files;

        if(afiles.length > 3) {
            this.setState({
                modalAction: -1,
                modalText: `¡ATENCIÓN! No se pueden adjuntar más de 3 ficheros a la vez y ha seleccionado ${afiles.length}.`,
                modalType: 1,
                creationModalOpen: true,
                modalClassName:'dialog-little'
            })
            return;
        }

        let lines = [];
        let nfiles = [];
        for (let index = 0; index < afiles.length; index++) {
            const element = afiles[index];
            nfiles.push(element);
            lines.push(<tr><td style={{borderRight:'2px solid #337ab7'}}>{`${element.name}`}</td><td>{`${(element.size/1048576).toFixed(2)} MB`}</td></tr>)
        }
        const items = <div><p>{`¿Quiere adjuntar los siguientes ficheros?`}</p><table><thead><tr><th>{`Nombre`}</th><th>{`Tamaño`}</th></tr></thead><tbody key={1}>{lines}</tbody></table></div>;

        this.setState({
            modalAction: 1,
            modalText: items,
            modalType: 2,
            creationModalOpen: true,
            modalClassName:'dialog-little',
            going2attach: {files: nfiles, row:personal}
        });
        /*const maxlength = Math.max.apply(Math, afiles.map(function(o:any) { return o.size; }))
        if(maxlength > 1200000) {
            this.setState({
                modalAction: -1,
                modalText: `¡ATENCIÓN! Hay almenos un fichero que ocupa más de 5 MB.`,
                modalType: 1,
                creationModalOpen: true
            })
            return;
        }*/
    }

    drawOnCanvas() {
        const item = cloneDeep(this.state.going2attach);
        const files:any = item.files;
        const row:IPersonal = item.row;

        let count = 0;

        for (let index = 0; index < files.length; index++) {
            const element = files[index];

            let reader = new FileReader();

            reader.onloadend = async ()=>{
                count++;
                this._going2upload.attachments.push({name: `Informe_Apto_Limitaciones.pdf`, stream: reader.result, centrotrabajoId: row.centrotrabajoId, tipo: 3, listadoId: 1, id: row.id, metadata: "{}"});
                //let response = await OrderService.createImgBlob({attachments:[{name: element.name, stream: reader.result, centrotrabajoId: this.props.match.params.centrotrabajoId, tipo: this.props.match.params.tipo, listadoId: this.props.match.params.id}]}, 6);

                if(count == files.length){
                    this.sendAttachFiles();
                                    
                }
            }
            reader.readAsDataURL(element);
        }
    }

    renderFormSelected=(id:number, me:IMe, sregister:IPersonal, options:any, personal:Array<IPersonal>)=>{
        switch (id) {
            case 1:
                return(
                    <Form_EPIs_03
                        me={me}
                        handleCloseItemForm={this.onCloseForm}
                        item={sregister}
                        puestosTrabajo={options["puestoTrabajo"]}
                        trabajadores={personal}
                    />        
                );
            case 2:
                return(
                    <Cert_01_info
                        me={me}
                        handleCloseItemForm={this.onCloseForm}
                        item={sregister}
                        puestosTrabajo={options["puestoTrabajo"]}
                        trabajadores={personal}
                    />        
                );
            case 3:
                return(
                    <Use_Machine_01
                        me={me}
                        handleCloseItemForm={this.onCloseForm}
                        item={sregister}
                        puestosTrabajo={options["puestoTrabajo"]}
                        trabajadores={personal}
                    />        
                );
            default:
                return(null);
        }
    }
    render() {

        const {loadingDocuments, options, documents, searchValue, creatingItem, createForm, numform, sregister, loadingOptions, filter_id} = this.state;
        const {me, filters} = this.props;
        const empresa:ICentroTrabajo|undefined = GetEmpresa(this.props.match.params.centrotrabajoId, me);

        const readonly = me?
                            ([10,11,20,50,100].indexOf(GetRole(me, this.props.match.params.centrotrabajoId)) == -1?true:false)
                        :
                            true;
        let title = (
            <div className='App-title-webPage'>
                {`Panel personal`}
                {'  '}
                {/*<Loader loading={this.state.loading} />*/}
            </div>
        );

        const apply_filters: any = filters[filter_id];

        return (
            <div className='App-basic'>
                <Layout 
                    history={this.props.history} 
                    currentPage={1}
                    allowDrawer={true}
                    match={this.props.match}
                    version={this.props.version}
                    me={me}
                    showEmpresa
                    showLateralMenu
                />
                <div className='App-body-webBar'>
                    {me && options?
                        [5,10,11,20,50,100].indexOf(GetRole(me, this.props.match.params.centrotrabajoId)) == -1?
                            <div>
                                <div className="App-header">
                                    {title}
                                </div>
                                <label style={{ marginLeft:10 }}>
                                    {`No tiene permisos para visualizar este espacio.`}
                                </label>
                            </div>
                        :
                            <div>
                                <div className="App-header">
                                    {title}
                                    <div className="App-header-options App-header-options-responsive">
                                        <SearchField placeholder="Buscar..." value={searchValue} allowSearch={true} onValueChange={this.handleSearchChange} handleClearSearchValue={this.handleClearSearchValue}/>
                                        <FiltersResponsive
                                            loadingOptions={loadingDocuments || loadingOptions}
                                            RenderFilters={this.RenderFilters}
                                            apply_filters={apply_filters}
                                            handleCleanFilters={this.handleCleanFilters}
                                            options={documents} 
                                        />
                                    </div>
                                </div>                                
                                <div>
                                    <PaginatedTable
                                        data={this.handleFilterDocuments(documents, apply_filters)}
                                        handleSearch={this.handleSearchChange}
                                        searchValue={searchValue}
                                        onClearSearchValue={this.handleClearSearchValue}
                                        handleItemClick={this.handleItemClick}
                                        handleClickOptionRow={(register:IPersonal, option:number)=>this.handleClickOptionRow(register, option, readonly)}
                                        handleAttachFiles={this.handleAttachFiles}
                                        me={me}
                                        centrotrabajoId={this.props.match.params.centrotrabajoId}
                                    />
                                </div>
                                {this.state.creationModalOpen?
                                    <CreateModal
                                        onCloseCreationModal={this.onCloseCreationModal}
                                        isOpen={this.state.creationModalOpen}
                                        inputText={this.state.modalText}
                                        typeModal={this.state.modalType}
                                        action={this.handleCreateModal}
                                        typeAction={this.state.modalAction}
                                        loadingModal={this.state.loadingModal}
                                        titleModal={this.state.titleModal}
                                    />
                                :
                                    null
                                }

                                {createForm && sregister?
                                    this.renderFormSelected(numform,me,sregister,options,documents.map((item:IPersonal, idx:number)=>{return {...item, name:`${item.name} ${item.surname}`}}))
                                :
                                    null
                                }
                            </div>
                    :
                        (loadingOptions || loadingDocuments)?
                            <div style={{marginLeft:20}}>
                                <Loader loading={true}/>
                            </div>
                        :
                            null
                    }
                    
                    <LoaderBeauty visible={(loadingDocuments||loadingOptions)?true:false}/>
                </div> 
            </div>
        );
    }
}

export default Listado;