import * as React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Loader from './Loader';
import LoaderCreateFile from "./Material/activityProgressCreateFile";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import './CreateDialog.css';

interface ICreateModalProps {
    isOpen: boolean;
    onCloseCreationModal?: any;
    inputText: string;
    typeModal: number;
    action?: Function;
    typeAction?: number;
    loadingModal?: boolean;
    titleModal?: string;
    closeButton?: boolean;
    fullscreen?: boolean;
    maxwidth?: any;
    className?: string
    dialogContentClassName?: string;
    loaderType?: number;
};

interface ICreateModalState {
}

class CreateModal extends React.Component<ICreateModalProps, ICreateModalState> {

    constructor(props: ICreateModalProps) {
        super(props);
        this.state = {
        };
    }

    handleAction = () => {
        if (this.props.action !== undefined) {
            this.props.action(this.props.typeAction);
        }
    }

    renderButtons = () => {
        if (!this.props.loadingModal) {
            switch (this.props.typeModal) {
                case 1:
                    return (
                        <DialogActions>
                            <Button onClick={this.props.onCloseCreationModal} className="confirm-dialog">
                                {`Ok`}
                            </Button>
                        </DialogActions>
                    );
                case 2:
                    return (
                        <DialogActions>
                            <Button style={{ marginRight: 'auto' }} onClick={() => this.props.onCloseCreationModal(this.props.typeAction)} className="cancel-dialog">
                                {`No`}
                            </Button>
                            <Button style={{ marginLeft: 'auto' }} onClick={this.handleAction} className="confirm-dialog">
                                {`Sí`}
                            </Button>
                        </DialogActions>
                    );
                case 3:
                    return (
                        <DialogActions>
                            <Button onClick={this.handleAction} className="confirm-dialog">
                                {`Guardar`}
                            </Button>
                        </DialogActions>
                    );
                case 4:
                    return (
                        <DialogActions>
                            <Button onClick={this.handleAction} className="confirm-dialog">
                                {`Ok`}
                            </Button>
                        </DialogActions>
                    );
                default:
                    break;
            }
        } else {
            let loader = <Loader loading={true} />;

            if (this.props.loaderType)
                switch (this.props.loaderType) {
                    case 1:
                        loader = <LoaderCreateFile />
                        break;
                    default:
                        break;
                }

            return (
                <DialogActions style={{ marginLeft: 'auto', marginRight: 'auto', padding: 5, marginTop: 20 }}>
                    {loader}
                </DialogActions>
            );
        }
    }

    render() {
        return (
            <div>
                <Dialog
                    open={this.props.isOpen}
                    keepMounted
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                    fullWidth={this.props.fullscreen}
                    maxWidth={this.props.maxwidth}
                    className={this.props.className}
                >
                    <DialogTitle style={{ marginRight: 30 }} id="alert-dialog-slide-title">{this.props.titleModal !== undefined ? this.props.titleModal : "MENSAJE"}</DialogTitle>
                    {this.props.closeButton ?
                        <IconButton aria-label="close" style={{ position: 'absolute', top: 10, right: 10 }} onClick={this.props.onCloseCreationModal}>
                            <CloseIcon />
                        </IconButton>
                        :
                        null
                    }
                    <DialogContent className={this.props.dialogContentClassName} style={{ textAlign: 'justify', lineHeight: 1.5 }}>
                        {this.props.inputText}
                    </DialogContent>
                    {this.renderButtons()}
                </Dialog>
            </div>
        );
    }
}

export default CreateModal;