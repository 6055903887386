import { Height } from "@mui/icons-material";
import guantes from '../Assets/epis/guantes.svg';

export const SimpleJsonWeb = [
  {
    id: "-9223372036854775801",
    "ParentId": "-9223372036854775802",
    "Position": 1,
    "EntityId": 543,
    "UserId": "1",
    "ObjectType": 4,
    "DataType": null,
    name: "Silo 09",
    "Ud": "Kg",
    "DescriptionChart": "Tendencia últimas 24 h",
    "ChartType": 2,
    "LowValue": 0,
    "HighValue": 12000,
    "CreateAlarm": false,
    "SendMessage": false,
    "Calibrate": false,
    "Used": true,
    "ExploitationId": 1,
    "deviceTypeId": 3
  },
  {
    id: "-9223372036854775802",
    "ParentId": "-9223372036854775803",
    "Position": 1,
    "EntityId": null,
    "UserId": "1",
    "ObjectType": 5,
    "DataType": null,
    name: "Silos",
    "Ud": null,
    "DescriptionChart": null,
    "ChartType": null,
    "LowValue": null,
    "HighValue": null,
    "CreateAlarm": null,
    "SendMessage": null,
    "Calibrate": null,
    "Used": true,
    "ExploitationId": 1,
    "deviceTypeId": null,
    "children": []
  },
  {
    id: "-9223372036854775803",
    "ParentId": "-9223372036854775808",
    "Position": 2,
    "EntityId": null,
    "UserId": "1",
    "ObjectType": 3,
    "DataType": null,
    name: "Torrecortasa 34197",
    "Ud": null,
    "DescriptionChart": null,
    "ChartType": null,
    "LowValue": null,
    "HighValue": null,
    "CreateAlarm": true,
    "SendMessage": true,
    "Calibrate": false,
    "Used": true,
    "ExploitationId": 1,
    "deviceTypeId": null,
    "children": []
  },
  {
    id: "-9223372036854775804",
    "ParentId": "-9223372036854775805",
    "Position": 1,
    "EntityId": 549,
    "UserId": "1",
    "ObjectType": 4,
    "DataType": null,
    name: "Silo 01",
    "Ud": "Kg",
    "DescriptionChart": "Tendencia últimas 24 h",
    "ChartType": 2,
    "LowValue": 0,
    "HighValue": 12000,
    "CreateAlarm": false,
    "SendMessage": false,
    "Calibrate": false,
    "Used": true,
    "ExploitationId": 1,
    "deviceTypeId": 3
  },
  {
    id: "-9223372036854775805",
    "ParentId": "-9223372036854775807",
    "Position": 2,
    "EntityId": null,
    "UserId": "1",
    "ObjectType": 5,
    "DataType": null,
    name: "Silos",
    "Ud": null,
    "DescriptionChart": null,
    "ChartType": null,
    "LowValue": null,
    "HighValue": null,
    "CreateAlarm": null,
    "SendMessage": null,
    "Calibrate": null,
    "Used": true,
    "ExploitationId": 1,
    "deviceTypeId": null,
    "children": []
  },
  {
    id: "-9223372036854775806",
    "ParentId": "-9223372036854775807",
    "Position": 1,
    "EntityId": null,
    "UserId": "1",
    "ObjectType": 5,
    "DataType": null,
    name: "Temperatura/Humedad",
    "Ud": null,
    "DescriptionChart": null,
    "ChartType": null,
    "LowValue": null,
    "HighValue": null,
    "CreateAlarm": null,
    "SendMessage": null,
    "Calibrate": null,
    "Used": true,
    "ExploitationId": 1,
    "deviceTypeId": null
  },
  {
    id: "-9223372036854775807",
    "ParentId": "-9223372036854775808",
    "Position": 1,
    "EntityId": null,
    "UserId": "1",
    "ObjectType": 3,
    "DataType": null,
    name: "Torrecortasa 33715",
    "Ud": null,
    "DescriptionChart": null,
    "ChartType": null,
    "LowValue": null,
    "HighValue": null,
    "CreateAlarm": true,
    "SendMessage": true,
    "Calibrate": false,
    "Used": true,
    "ExploitationId": 1,
    "deviceTypeId": null,
    "children": []
  },
  {
    id: "-9223372036854775808",
    "ParentId": null,
    "Position": 1,
    "EntityId": null,
    "UserId": "1",
    "ObjectType": 1,
    "DataType": null,
    name: "Dashboards",
    "Ud": null,
    "DescriptionChart": null,
    "ChartType": null,
    "LowValue": null,
    "HighValue": null,
    "CreateAlarm": true,
    "SendMessage": true,
    "Calibrate": false,
    "Used": true,
    "ExploitationId": 1,
    "deviceTypeId": null,
    "children": []
  }
];
export const referencia = [
{
  id: 1,
  code: "010",
  name: "CAÍDA DE PERSONAS A DISTINTO NIVEL"
},
{
  id: 2,
  code: "030",
  name: "CAÍDA DE OBJETOS POR DESPLOME"
},
{
  id: 3,
  code: "040",
  name: "CAÍDA DE OBJETOS EN MANIPULACÓN"
},
{
  id: 4,
  code: "100",
  name: "PROYECCÓN DE FRAGMENTOS O PARTÍCULAS"
},
{
  id: 5,
  code: "200",
  name: "EXPLOSIONES"
},
{
  id: 6,
  code: "240",
  name: "ACCIDENTES DE TRÁFICO"
},
{
  id: 7,
  code: "302",
  name: "FACTORES ERGONÓMICOS. POSTURAS FORZADAS"
},
];
export const origen =[
{id:1, name: "ESTUDIOS ESPECÍFICOS PRL"},
{id:2, name: "EVALUACIÓN DE RIESGOS"},
{id:3, name: "REUNIONES COMITÉ SS"},
{id:4, name: "VISITAS A PLANTA"}
];
export const seccion =[
{id:1, name: "CONGELAT PATEL 2"},
{id:2, name: "ENVASAT PATEL 2"},
{id:3, name: "MANTENIMIENTO"},
{id:4, name: "MATADERO"},
{id:5, name: "MOLL PATEL 1"},
{id:6, name: "NETEJA DE CAIXES"},
{id:7, name: "OFICINAS"}
];
export const puestos = [
{id:1, name: "ADMINISTRACIÓN"},
{id:2, name: "CARRETILLERO"},
{id:3, name: "ENCARGADO MUELLE"},
{id:4, name: "JEFE DE CARGA"},
{id:5, name: "MOZO DE CUADRA"},
{id:6, name: "OFICIAL MATADERO ZONA SUCIA"},
{id:7, name: "OFICIAL ZONA LIMPIA"}
];
export const responsable = [
{id:1, name: "CALIDAD"},
{id:2, name: "MANTENIMIENTO"},
{id:3, name: "PRL"},
{id:4, name: "PRODUCCIÓN"},
{id:5, name: "RRHH"}
];
export const estado = [
{id:10, name: "PENDIENTE"},
{id:20, name: "EN PROCESO"},
{id:30, name: "RESUELTA"},
{id:99, name: "ELIMINADA"},
];
export const documents = [
{
  id:1, 
  tipo:1, 
  code:"MP-001", 
  origen:2,
  origenDsc:"Evaluación de riesgos",
  referencia:3,
  referenciaCode:"040",
  referenciaDsc:"Caída de objetos en manipulación", 
  seccion:2, 
  seccionDsc:"ENVASAT PATEL 2", 
  puesto:6, 
  puestoDsc:"OFICIAL MATADERO ZONA SUCIA", 
  estado:20, 
  estadoDsc:"EN PROCESO", 
  prioridad:1, 
  prioridadDsc:"Media/Alta",
  centrotrabajoId:"vc"
},
{
  id:2, 
  tipo:2, 
  code:"MC-001", 
  origen:2,
  origenDsc:"Evaluación de riesgos",
  referencia:3,
  referenciaCode:"040",
  referenciaDsc:"Caída de objetos en manipulación", 
  seccion:2, 
  seccionDsc:"ENVASAT PATEL 2", 
  puesto:6, 
  puestoDsc:"OFICIAL MATADERO ZONA SUCIA", 
  estado:20, 
  estadoDsc:"EN PROCESO", 
  prioridad:1, 
  prioridadDsc:"Media/Alta",
  centrotrabajoId:"vc"
}
];
export const me = {
userId:'sprunera@vallcompanys.es',
activated:true,
userName:'Sergi Prunera',
empresas:[
  {centrotrabajoId:'vc', name:"Vall Companys", role:10},
  {centrotrabajoId:'ac', name:"Agrocesa", role:10},
  {centrotrabajoId:'pdx', name:"Pondex", role:10},
  {centrotrabajoId:'mvt', name:"Mevet", role:10}
]
}

export const epis =[
{id:1,name:"PI BOCA/NARIZ - MASCARILLA QUIRURGICA"},
{id:2,name:"PI BOCA/NARIZ - MASCARILLA HIGIENICA"},
{id:3,name:"PI BOCA/NARIZ - MASCARILLA FFP1"},
{id:4,name:"PI BOCA/NARIZ - MASCARILLA FFP1 CON VALVULA"},
{id:5,name:"PI BOCA/NARIZ - MASCARILLA FFP2"},
{id:6,name:"PI BOCA/NARIZ - MASCARILLA FFP2 CON VALVULA"},
{id:7,name:"PI BOCA/NARIZ - MASCARILLA FFP3"},
{id:8,name:"PI BOCA/NARIZ - MASCARILLA FFP3 CON VALVULA"},
{id:9,name:"PI BOCA/NARIZ - MEDIA MASCARA GASES Y VAPORES"},
{id:10,name:"PI BOCA/NARIZ - MASCARA COMPLETA GASES Y VAPORES"},
{id:11,name:"PI BOCA/NARIZ - FILTROS MASCARILLAS GASES Y VAPORES"},
{id:12,name:"PI CARA/CUELLO - PANTALLA FACIAL (COVID)"},
{id:13,name:"PI CARA/CUELLO - PANTALLA FACIAL SOLDADURA"},
{id:14,name:"PI CRANEO - CASCO DE PROTECCION"},
{id:15,name:"PI CRANEO - GORRA-CASCO MANTENIMIENTO"},
{id:16,name:"PI CRANEO - CASCO DE PROTECCION CON BARBUQUEJO"},
{id:17,name:"PI CRANEO - CASCO DE PROTECCION VISERA RETRACTIL"},
{id:18,name:"PI CUERPO/TORAX - ARNES ANTICAIDA"},
{id:19,name:"PI CUERPO/TORAX - FAJA"},
{id:20,name:"PI CUERPO/TORAX - CHAQUETA FRIO FRIGORIFICO"},
{id:21,name:"PI CUERPO/TORAX - CHALECO REFLECTANTE"},
{id:22,name:"PI CUERPO/TORAX - MONO FRIO FRIGORIFICO"},
{id:23,name:"PI CUERPO/TORAX - BUZO AMONIACO"},
{id:24,name:"PI CUERPO/TORAX - MANDIL SOLDADURA"},
{id:25,name:"PI CUERPO/TORAX - DELANTAL MALLA INOX"},
{id:26,name:"PI MANO/BRAZO - GUANTES LATEX"},
{id:27,name:"PI MANO/BRAZO - GUANTES ANTICORTE INOX MALLA"},
{id:28,name:"PI MANO/BRAZO - GUANTES NITRILO"},
{id:29,name:"PI MANO/BRAZO - GUANTES FRIO LANA"},
{id:30,name:"PI MANO/BRAZO - TENSOR GUANTE ANTICORTE INOX-MALLA"},
{id:31,name:"PI MANO/BRAZO - GUANTE DESCORTEZADORA -TOWNSEND"},
{id:32,name:"PI MANO/BRAZO - GUANTE FRIO POLIAMIDA"},
{id:33,name:"PI MANO/BRAZO - GUANTE ANTICORTE"},
{id:34,name:"PI MANO/BRAZO - GUANTE VINILO"},
{id:35,name:"PI MANO/BRAZO - GUANTE LAVAPLATOS"},
{id:36,name:"PI MANO/BRAZO - GUANTE RIESGO MECANICO"},
{id:37,name:"PI MANO/BRAZO - GUANTE SOLDADURA"},
{id:38,name:"PI MANO/BRAZO - GUANTE RIESGO QUIMICO"},
{id:39,name:"PI MANO/BRAZO - GUANTE PIEL FLOR"},
{id:40,name:"PI OJOS - GAFAS SEGURIDAD IMPACTOS"},
{id:41,name:"PI OJOS - GAFAS DE SEGURIDAD GRADUADAS"},
{id:42,name:"PI OJOS - GAFAS DE SEGURIDAD ESTANCAS"},
{id:43,name:"PI OREJAS - PROTECTOR AUDITIVO -OREJAS NUCA"},
{id:44,name:"PI OREJAS - TAPONES PROT AUDITIVA"},
{id:45,name:"PI OREJAS - OREJERAS COMUNICACIÓN ELECTRONICA"},
{id:46,name:"PI OREJAS - PROTECTOR AUDITIVO -DIADEMA"},
{id:47,name:"PI OREJAS - TAPONES PROTECCION AUDITIVA DESECHABLES"},
{id:48,name:"PI OREJAS - PROTECCION AUDITIVA  S/CASCO PROTECCION"},
{id:49,name:"PI PIERNAS/PIE - RODILLERAS MTNO"},
{id:50,name:"PI PIERNAS/PIE - BOTAS SEGURIDAD"},
{id:51,name:"PI PIERNAS/PIE - BOTAS AGUA SEGURIDAD"},
{id:52,name:"PI PIERNAS/PIE - ZAPATOS SEGURIDAD"},
{id:53,name:"PI PIERNAS/PIE - BOTAS SEGURIDAD ESPECIALES"},
{id:54,name:"PI PIERNAS/PIE - PANTALON ANTICORTE INOX"},
{id:55,name:"PI PIERNAS/PIE - PANTALON FRIO FRIGORIFICO"},
{id:56,name:"RT BOCA/NARIZ - ROPA TRABAJO BOCA-NARIZ"},
{id:57,name:"RT CARA/CUELLO - BRAGA DE CUELLO FRIO -BUFF"},
{id:58,name:"RT CRANEO - PASAMONTAÑAS"},
{id:59,name:"RT CRANEO - GORRA BASEBALL PROTECCION SOLAR"},
{id:60,name:"RT CRANEO - GORRO"},
{id:61,name:"RT CUERPO/TÓRAX. - BATAS DE TELA"},
{id:62,name:"RT CUERPO/TÓRAX. - MONOS"},
{id:63,name:"RT CUERPO/TÓRAX. - CAMISAS/CAMISETAS"},
{id:64,name:"RT CUERPO/TÓRAX. - CHALECO"},
{id:65,name:"RT CUERPO/TÓRAX. - CHAQUETA IMPERMEABLE"},
{id:66,name:"RT CUERPO/TÓRAX. - SUDADERAS"},
{id:67,name:"RT CUERPO/TÓRAX. - OTRAS PRENDAS TBJO"},
{id:68,name:"RT CUERPO/TÓRAX. - OTRAS PRENDAS TBJO CONGELADO"},
{id:69,name:"RT CUERPO/TÓRAX. - DELANTALES POLIUTERANO"},
{id:70,name:"RT CUERPO/TÓRAX. - BATA IMPERMEABLE"},
{id:71,name:"RT CUERPO/TÓRAX. - CHAQUETA DE TELA"},
{id:72,name:"RT CUERPO/TÓRAX. - CAMISETA ALGODÓN"},
{id:73,name:"RT CUERPO/TÓRAX. - POLO"},
{id:74,name:"RT CUERPO/TÓRAX. - JERSEY"},
{id:75,name:"RT CUERPO/TÓRAX. - CAZADORA"},
{id:76,name:"RT CUERPO/TÓRAX. - ANORAK"},
{id:77,name:"RT MANO/BRAZO - MANGUITO PLASTICO"},
{id:78,name:"RT MANO/BRAZO - SUJETADOR GUANTE -ARAÑA"},
{id:79,name:"RT MANO/BRAZO - GUANTE LANA"},
{id:80,name:"RT OREJAS - OREJERAS FRIO"},
{id:81,name:"RT PIERNAS/PIE - PANTALON TELA"},
{id:82,name:"RT PIERNAS/PIE - BOTAS AGUA"},
{id:83,name:"RT PIERNAS/PIE - PANTALON IMPERMEABLE"},
{id:84,name:"RT PIERNAS/PIE - FORRO BORREGUILLO BOTAS"},
{id:85,name:"MD - CUBRE BARBA"},
{id:86,name:"MD - CALZAS (CubreZapatos)"},
{id:87,name:"MD - PASAMONTAÑAS SIN MASCARILLA"},
{id:88,name:"MD - GORROS TAPA MELENA (COFIA)"},
{id:89,name:"MD - MANGUITOS"},
{id:90,name:"MD - PASAMONTAÑAS CON MASCARILLA"},
{id:91,name:"MD - MATERIAL DESECHABLE BOCA-NARIZ"},
{id:92,name:"MD - MATERIAL DESECHABLE OREJAS"},
{id:93,name:"MD - DELANTAL"},
{id:94,name:"MD - BUZO"},
{id:95,name:"MD - BATA"},
{id:96,name:"MD - MANGUITOS"},
];

export const episGroup = [
  {
      id: 1,
      title: "GAFAS O PANTALLAS FACIALES DE PROTECCIÓN",
      riskDetail: "SALPICADURAS DE PRODUCTOS QUÍMICOS\r\nCORROSIVOS E IRRITANTES\r\nPROYECCIÓN DE PARTÍCULAS\r\nRADIACIONES NO IONIZANTES (SOLDADURA)",
      dsc:"",
      ud: 1,
      icon: guantes
  },
  {
      id: 2,
      title: "PROTECCIÓN AUDITIVA (TAPONES, CASCOS)",
      riskDetail: "PROTECCIÓN AUDITIVA PARA LA EXPOSICIÓN A NIVELES ELEVADOS DE RUIDO",
      dsc:"",
      ud: 1,
      icon: guantes
  },
  {
      id: 3,
      title: "PROTECCION EXTREMIDADES SUPERIORES (GUANTES, MANOPLAS)",
      riskDetail: "GOLPES\r\nCORTES\r\nCONTACTOS TÉRMICOS\r\nCONTACTOS ELÉCTRICOS\r\nCONTACTOS CON PRODUCTOS QUÍMICOS, CORROSIVOS O IRRITANTES ",
      dsc:"",
      ud: 1,
      icon: guantes
  },
  {
      id: 4,
      title: "PROTECCIÓN DE LAS EXTREMIDADES INFERIORES (BOTAS, ZAPATOS DE SEGURIDAD)",
      riskDetail: "CAÍDAS DE OBJETOS AL PIE\r\nGOLPES AL PIE CON PLANTILLA REFORZADA\r\nANTIPINCHAZOS\r\nBOTAS ALTAS DE GOMA",
      dsc:"",
      ud: 1,
      icon: guantes
  },
  {
      id: 5,
      title: "PROTECCIÓN DEL APARATO RESPIRATORIO",
      riskDetail: "INHALACIÓN  DE AGENTES QUÍMICOS PELIGROSOS (NOCIVOS, TÓXICOS O IRRITANTES) PRESENTES EN EL AMBIENTE DE TRABAJO",
      dsc:"",
      ud: 1,
      icon: guantes
  },
  {
      id: 6,
      title: "PROTECCIÓN CONTRA CAÍDAS EN ALTURA (ARNÉS O CINTURÓN DE SEGURIDAD)",
      riskDetail: "CAÍDAS A DIFERENTE NIVEL",
      dsc:"",
      ud: 1,
      icon: guantes
  },
  {
      id: 7,
      title: "PROTECCIÓN DE LA CABEZA (CASCO DE SEGURIDAD)",
      riskDetail: "CAÍDA DE OBJETOS\r\nCHOQUES",
      dsc:"",
      ud: 1,
      icons: [8]
  },
  {
      id: 8,
      title: "OTROS",
      riskDetail: "",
      dsc:"",
      ud: 1,
      icons: []
  }
]

export const AuditFields = [
{
  id: 1,
  numeration: "A",
  name: "CONTEXTO DE LA ORGANIZACIÓN",
  description: "CONTEXTO DE LA ORGANIZACIÓN",
  order: 0,
  group: 2,
  subsections: [
    {
      id: 1,
      numeration: "A1",
      name: "Plan de Prevención",
      description: "Plan de Prevención (adaptado a la “realidad” de la empresa)",
      order: 0,
    }
  ]
},
{
  id: 2,
  numeration: "B",
  name: "LIDERAZGO Y PARTICIPACIÓN DE LOS TRABAJADORES",
  description: "LIDERAZGO Y PARTICIPACIÓN DE LOS TRABAJADORES",
  order: 1,
  group: 2,
  subsections: [
    {
      id: 1,
      numeration: "B1",
      name: "Política",
      description: "Política (comunicada y puesta a disposición de los trabajadores – web, paneles de anuncios, pantallas…)",
      order: 0
    },
    {
      id: 2,
      numeration: "B2",
      name: "Estructura organizativa – Funciones y responsabilidades",
      description: "Estructura organizativa – Funciones y responsabilidades (organigrama – Coordinador PRL, Delegados de Prevención / Comité SS, recursos preventivos…; definición de funciones y responsabilidades y evidencia de comunicación a los trabajadores, DPT – puesta a disposición de DP / CSS y comunicado a todo el personal; designación recursos preventivos)",
      order: 1
    },
    {
      id: 3,
      numeration: "B3",
      name: "Consulta y participación",
      description: "Consulta y participación (herramientas: buzón sugerencias, Proyecto PARTICIPA, paneles anuncios-pantallas, portal del empleado, reuniones internas-CSS, Compliance: Código Ético, Protocolo acoso laboral…)",
      order: 2
    }
  ]
},
{
  id: 3,
  numeration: "C",
  name: "PLANIFICACIÓN",
  description: "PLANIFICACIÓN",
  group: 2,
  order: 2,
  subsections: [
    {
      id: 1,
      numeration: "C1",
      name: "Evaluación y control de riesgos",
      description: "Evaluación y control de riesgos (evaluación de instalaciones / secciones, puestos de trabajo y máquinas / equipos de trabajo; evaluación por puesto de trabajo con relación de: productos químicos utilizados, máquinas y equipos de trabajo, EPIs; relación de trabajadores por puestos de trabajo según EVR y teniendo en cuenta rotaciones del personal; existencia de trabajadores especialmente sensibles en la evaluación de puestos…)",
      order: 0
    },
    {
      id: 2,
      numeration: "C2",
      name: "Estudios específicos",
      description: "Estudios específicos (ruido; PVDs; ergonómicos - mmc, repetitivos, posturas forzadas; iluminación; termohigrométricos; estrés térmico; agentes químicos; agentes biológicos; psicosociales; vibraciones)",
      order: 1
    },
    {
      id: 3,
      numeration: "C3",
      name: "Planificación preventiva – Programación anual SP",
      description: "Planificación preventiva – Programación anual SP (registro y seguimiento de medidas preventivas y correctoras en la APP y en actas de reuniones internas / Comité SS; actividades programadas con el SP, seguimiento y ejecución)",
      order: 2
    },
    {
      id: 4,
      numeration: "C4",
      name: "Objetivos",
      description: "Objetivos (definición y seguimiento)",
      order: 3
    },
    {
      id: 5,
      numeration: "C5",
      name: "Memorias Servicios de Prevención",
      description: "Memorias Servicios de Prevención (memorias anuales de las 3 especialidades técnicas y de vigilancia de la salud – incluir estudio epiedemiológico; valoración del grado de integración de la PRL en la empresa)",
      order: 4
    }
  ]
},
{
  id: 4,
  numeration: "D",
  name: "APOYO",
  description: "APOYO",
  order: 3,
  group: 2,
  subsections: [
    {
      id: 1,
      numeration: "D1",
      name: "Formación e información",
      description: "Formación e información (registros de formación de puesto art. 19 y formación específica; registros de entrega de información art. 18; soporte formativo por puesto de trabajo – riesgos, emergencias, fichas técnicas; autorización de uso de equipos automotores y máquinas 'peligrosas'; Plan de formación anual)",
      order: 0
    },
    {
      id: 2,
      numeration: "D2",
      name: "Comunicación interna y externa",
      description: "Comunicación interna y externa (actas reuniones internas y externas – CAE, mails, citaciones Inspección Trabajo, comunicados de Industria, tríptico visitas…)",
      order: 1
    }
  ]
},
{
  id: 5,
  numeration: "E",
  name: "OPERACIÓN",
  description: "OPERACIÓN",
  order: 4,
  group: 1,
  subsections: [
    {
      id: 1,
      numeration: "E1",
      name: "Listado de procedimientos",
      description: "Listado de procedimientos (procedimientos, instrucciones de trabajo… del Grupo y específicos de la empresa)",
      order: 0
    },
    {
      id: 2,
      numeration: "E2",
      name: "Vigilancia de la salud",
      description: "Vigilancia de la salud (protocolos médicos por puesto de trabajo y “correctos” según los riesgos existentes en la EVR del puesto; “aptos con limitaciones” adaptación del puesto, comunicación escrita al trabajador y constancia en la evaluación del puesto; verificar renuncias firmadas; vacunas - tétanos recomendable para todo el personal, hepatitis A necesario para depuradora, verificar que estén detalladas en el documento de protocolos y en caso necesario formato renuncia firmado por el trabajador)",
      order: 1
    },
    {
      id: 3,
      numeration: "E3",
      name: "Trabajadores externos",
      description: "Trabajadores externos (metodología de control – email / CTAIMA; % cumplimiento y documentos información en CTAIMA; registro documentación en share point CONTRATAS; actas de reunión con empresas externas habituales en el centro de trabajo - outsourcing-mano de obra, ETTs, empresas de limpieza, empresas de mantenimiento asiduas; trabajos “limitantes” personal ETT; ETTs: EVR puesto, estudios específicos y entrega EPIs responsabilidad de la empresa GVC. Verificar que no es un puesto nuevo de trabajo, si no una 'ayuda' a tareas ya existentes que realiza personal de empresa)",
      order: 2
    },
    {
      id: 4,
      numeration: "E4",
      name: "Emergencias",
      description: "Emergencias (Plan emergencia / PAU, formación y designación del equipo de emergencia – todos los turnos, simulacros – todos los turnos, registro y seguimiento en APP de desviaciones detectadas en simulacros, registro PAU a administración y actualización del documento – cada 3 años, cada 4 en Cataluña)",
      order: 3
    }
  ]
},
{
  id: 6,
  numeration: "F",
  name: "EVALUACIÓN DEL DESEMPEÑO",
  description: "EVALUACIÓN DEL DESEMPEÑO",
  order: 5,
  group: 1,
  subsections: [
    {
      id: 1,
      numeration: "F1",
      name: "EPIs",
      description: "EPIs (registros entrega “correctos” según EVR, disponibilidad fichas técnicas, registro y revisiones / inspecciones periódicas de arneses, líneas de vida y ERAs, certificación de trajes químicos, certificación ropa de trabajo - EN 14058 ambientes fríos, EN 342 áreas de congelado)",
      order: 0
    },
    {
      id: 2,
      numeration: "F2",
      name: "Control periódico de condiciones",
      description: "Control periódico de condiciones (preventivos APP, OT programa mantenimiento; máquinas, equipos de trabajo, ITE estanterías industriales, escalas, plataformas de trabajo, uso de EPIs…)",
      order: 1
    },
    {
      id: 3,
      numeration: "F3",
      name: "Mantenimiento",
      description: "Mantenimiento (inventario maquinaria, estado CE - manual, Declaración Conformidad, placa y adecuación RD 1215-97 máquinas y líneas; plan de mantenimiento preventivo / correctivo de equipos de trabajo, máquinas e instalaciones; revisiones por empresas mantenedoras e inspecciones por OCA)",
      order: 2
    },
    {
      id: 4,
      numeration: "F4",
      name: "Permiso de trabajo",
      description: "Permiso de trabajo (grado de implantación, disponer de procedimiento escrito asociado al permiso para trabajadores propios o CAE)",
      order: 3
    },
    {
      id: 5,
      numeration: "F5",
      name: "Lotto / Consignación",
      description: "Lotto / Consignación (grado de implantación, disponibilidad de procedimiento para operativas de mantenimiento, limpieza con equipos / máquinas 'de riesgo')",
      order: 4
    },
    {
      id: 6,
      numeration: "F6",
      name: "Documento de Protección contra Explosiones – ATEX",
      description: "Documento de Protección contra Explosiones – ATEX (necesidad de tener el documento, “integración” con la evaluación de riesgos, formación del personal, información a personal propio y externo, procedimiento de trabajo)",
      order: 5
    },
    {
      id: 7,
      numeration: "F7",
      name: "Productos químicos",
      description: "Productos químicos (disponibilidad fichas datos de seguridad actualizadas; requerimientos almacén PQ: sistemas extinción, duchas y lavaojos, ventilación, señalización, incompatibilidad ácido-base para almacenamiento, sistemas de retención para derrames, envases identificados…, requiere legalización de Industria, inspector APQ designado)",
      order: 6
    },
    {
      id: 8,
      numeration: "F8",
      name: "Consejero de Seguridad",
      description: "Consejero de Seguridad (revisión informe anual y estado cumplimiento desviaciones; check-list de comprobación de descargas)",
      order: 7
    },
    {
      id: 9,
      numeration: "F9",
      name: "Instalaciones",
      description: "Instalaciones (otros temas generales)",
      order: 8
    }
  ]
},
{
  id: 7,
  numeration: "G",
  name: "MEJORA",
  description: "MEJORA",
  order: 6,
  group: 1,
  subsections: [
    {
      id: 1,
      numeration: "G1",
      name: "Accidentes, No Conformidades y acciones de mejora",
      description: "Accidentes, No Conformidades y acciones de mejora (registro de la investigación, cumplimentado y redactado correcto y claro y formato utilizado según última versión con firma de todos los implicados; enfermedades profesionales, profesiograma, medidas consideradas; informe de accidentabilidad Mutua con indicadores, nº accidentes con y sin baja, en caso de alta accidentabilidad disponibilidad de un plan de acción)",
      order: 0
    }
  ]
}
]

export const Dashboard =   
  {
    section:[
      {
        name:'Preventivos',
        kpi:{
          id: 1,
          name: 'Completados',        
          number: 0,
          showLoad: true,
          percentage: 0,
          total:0
        },
        graphics:[
          {
            id: 1,
            name: 'Estado',
            options: {
              tooltip: {
                trigger: 'item'
              },
              legend: {
                  top: 'bottom',
                  right: 5,
                  orient: 'horizontal',
                  left:'center',
                  type: 'scroll',
                  itemWidth: 20
              },
              color:['#2dc4bd', '#f7da52', '#ff8848', '#99e06d', '#9630ad'],
              series: [
                  {
                      name: 'Estado preventivos',
                      type: 'pie',
                      radius: ['40%', '70%'],
                      avoidLabelOverlap: false,
                      itemStyle: {
                          borderRadius: 10,
                          borderColor: '#fff',
                          borderWidth: 2
                      },
                      label: {
                          show: false,
                      },
                      labelLine: {
                          show: false
                      },
                      // width: 200,
                      // height:200,
                      data: [                          
                          {
                              "value": 1,
                              "name": "ESTUDIOS ESPECÍFICOS PRL"
                          },
                          {
                              "value": 2,
                              "name": "EVALUACIÓN DE RIESGOS"
                          },
                          {
                              "value": 1,
                              "name": "VISITAS A PLANTA"
                          },
                          {
                              "value": 1,
                              "name": "EMERGENCIAS"
                          },
                          {
                              "value": 3,
                              "name": "ATEX"
                          },
                          {
                              "value": 4,
                              "name": "AUDITORÍA INTERNA"
                          },
                          {
                              "value": 5,
                              "name": "ACCIDENTES EXTERNOS"
                          }
                      ]                      
                  }
              ]
            }
          },
          {
            id: 2,
            name: 'Orígen',            
            options: {
              title:{
                show:false
              },
              tooltip: {
                trigger: 'item'
              },
              legend: {
                  top: 'bottom',
                  left:'center',
                  orient: 'horizontal',
                  type: 'scroll',
                  itemWidth: 20
              },
              color:['#2dc4bd', '#f7da52', '#ff8848', '#99e06d', '#9630ad'],
              series: [
                  {
                      name: 'Orígen preventivos',
                      type: 'pie',
                      radius: ['40%', '70%'],
                      avoidLabelOverlap: true,
                      itemStyle: {
                          borderRadius: 10,
                          borderColor: '#fff',
                          borderWidth: 2
                      },
                      label: {
                          show: false,
                      },
                      labelLine: {
                          show: false
                      },
                      top:0,
                      tooltip:{
                        position:'inside',
                      },                      
                      data: [                                                  
                    ]   
                  }
              ]
            }
          },
        ]
      },
      {
        name:'Correctivos',
        kpi:{
          id: 2,
          name: 'Completados',        
          number: 0,
          showLoad: true,
          percentage: 0,
          total:0
        },
        graphics:[
      
          {
            id: 3,
            name: 'Estados',
            options: {
              tooltip: {
                  trigger: 'item'
              },
              legend: {
                  top: 'top',
                  right: 5,
                  orient: 'vertical',
              },
              color:['#2dc4bd', '#f7da52', '#ff8848', '#99e06d', '#9630ad'],
              series: [
                  {
                      name: 'Estados correctivos',
                      type: 'pie',
                      radius: ['40%', '70%'],
                      avoidLabelOverlap: false,
                      itemStyle: {
                          borderRadius: 10,
                          borderColor: '#fff',
                          borderWidth: 2
                      },
                      label: {
                          show: false                      
                      },                  
                      labelLine: {
                          show: false
                      },
                      height:200,
                      width: 200,
                      data: [
                          
                      ]
                  }
              ]
            }
          },
          {
            id: 4,
            name: 'Orígen',
            options: {
              tooltip: {
                trigger: 'item'
              },
              legend: {
                  top: 'center',
                  right: 10,
                  orient: 'vertical',
              },
              color:['#2dc4bd', '#f7da52', '#ff8848', '#99e06d', '#9630ad'],
              series: [
                  {
                      name: 'Orígen correctivos',
                      type: 'pie',
                      radius: ['40%', '70%'],
                      avoidLabelOverlap: false,
                      itemStyle: {
                          borderRadius: 10,
                          borderColor: '#fff',
                          borderWidth: 2
                      },
                      label: {
                          show: false,
                          position: 'center'
                      },
                      labelLine: {
                          show: false
                      },
                      height:250,
                      width: 250,
                      data: [
                          
                      ]
                  }
              ]
            }
          },
        ]
      }
    ]        
  }
