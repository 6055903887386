import { FormikHelpers } from "formik";
import * as React from "react";
import OrderService from "../../../../../ApiClients/OrderService";
import { InformeRouteType } from "../../../hooks/useInformesRouteParams";
import InformeUpdateForm from "../components/InformeUpdateForm";
import { Props, SubmitAction, Values } from "./types";
import { getValidationSchema } from "./validationSchema";
import CreateModal from "../../../../../Components/CreateDialog";
import Loader from "../../../../../Components/Material/activityProgressCreateFile";
import { InformeT } from "../../../types";
import InformeContext from "../../../hooks/InformeContext";
import { MyDocument } from "../../../containers/PdfTemplate";
import { ICentroTrabajo } from "../../../../../types";
import { pdf, PDFViewer } from "@react-pdf/renderer";
import { OpenExternalFile } from "../../../../../helpers";
import { Prompt } from "react-router-dom";

const InformeUpdateFormContainer = ({
  informe,
  handleNavigateToReports,
  informeRouteType,
  disabledFields: disabledFields$prop,
  ...props
}: Props) => {
  const [currentAction, setCurrentAction] = React.useState<SubmitAction>(
    SubmitAction.SAVE
  );
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [modalText, setModalText] = React.useState(`!ATENCIÓN! ¿Quiere publicar el informe "${informe.code}" y generar su PDF?`);
  const [modalTitle, setModalTitle] = React.useState(`Confirmar publicación`);
  const [modalType, setModalType] = React.useState(2);
  const [modalClassname, setmodalClassname] = React.useState("");

  const [isDataChanged, setIsDataChanged] = React.useState(false);

  const [c_informe, setInforme] = React.useState<InformeT>(informe);

  const handleClickSubmit = React.useCallback((action: SubmitAction) => {
    setCurrentAction(action);
  }, []);

  const onFormChange = React.useCallback((state: boolean)=>{
    // if(state)
    //   console.log('unsaved')
    // else
    //   console.log('saved')
    setIsDataChanged(state);
  }, []);

  const { generatePdfBlob, MyDocument, empresa } = React.useContext(InformeContext);

  const onSubmit = useUpdateInforme({ action: currentAction, handleNavigateToReports: handleNavigateToReports, setIsConfirmationModalOpen: setIsConfirmationModalOpen, setInforme: setInforme, setModalText: setModalText, setModalTitle: setModalTitle, setModalType: setModalType, informe_tipo: informeRouteType, empresa: empresa, setmodalClassname: setmodalClassname, onFormChange: onFormChange});

  const handleConfirmPublish = async () => {
    // setIsConfirmationModalOpen(false);    
    setModalTitle(`Proceso de publicación`)
    setModalText(`Publicando el informe "${informe.code}"...`);
    setLoading(true); // Activa el estado de carga antes de iniciar la operación
    //console.log(c_informe)
    try {
      if (empresa && generatePdfBlob && MyDocument) {
        //const pdfDocumentElement = MyDocument({ c_informe });
        c_informe.status = 50;
        generatePdfBlob(c_informe, empresa, `${c_informe.code}-informe.pdf`)
        .then((data:any)=>{
          //console.log(data)
          c_informe.dtReport = new Date().toISOString();
          c_informe.storage = data;
          //let response = await OrderService.createImgBlob(this._going2upload, 8);

          //OrderService.saveData(c_informe, 18)
          const attach = {attachments: [{name: `Informe_${c_informe.centrotrabajoId}_${c_informe.tipo}_${c_informe.id}.pdf`, stream: c_informe.storage, centrotrabajoId: c_informe.centrotrabajoId, tipo: 4, listadoId: c_informe.tipo, id: c_informe.id, metadata: c_informe}]};
          OrderService.createImgBlob(attach, 18)
          .then((ret)=>{
            console.log("Successfully Published informe: ", ret);
            if(onFormChange)
              onFormChange(false);
            setTimeout(() => {
              handleNavigateToReports(informeRouteType);
            }, 1000);
          })
          .catch((err)=>{
            console.error(err);
          })
          .finally(()=>{
            setTimeout(() => {
              setLoading(false); // Desactiva el estado de carga al finalizar la operación
              setIsConfirmationModalOpen(false);
      
              setModalTitle(`Confirmar publicación`);
              setModalText(`!ATENCIÓN! ¿Quiere publicar el informe "${informe.code}" y generar su PDF?`);
            }, 500);
          });
          //history.goBack(); 
        })
        .catch((error)=> {

        })      
      }
    } catch (error) {
      console.error("Error al publicar el informe:", error);
    } finally {

    }
  };

  const initialValues = React.useMemo(
    () => ({
      // Always readOnly
      id: informe.id,
      code: informe.code ?? "",
      storage: informe.storage ?? null,
      // version: informe.version,
      status: informe.status ?? null,
      statusDsc: informe.statusDsc ?? "",
      statusColor: informe.statusColor ?? "",
      dtCreated: informe.dtCreated,
      centrotrabajoId: informe.centrotrabajoId,

      type: informeRouteType,
      tipo: informe.tipo,
      author: informe.author ?? "",

      // Updatable values
      name: informe.name ?? "",
      description: informe.description ?? "",
      team: informe.team ?? "",
      participant: informe.participant ?? "",
      globalComment01: informe.globalComment01 ?? "",
      globalComment02: informe.globalComment02 ?? "",

      dtStartVisit: informe.dtStartVisit ?? null,
      dtEndVisit: informe.dtEndVisit ?? null,
      dtReport: informe.dtReport ?? null,
      sections: informe.sections ?? [],
      mark: informe.mark ?? null,
      markOperationalControl: informe.markOperationalControl ?? null,
      markDocumentManagement: informe.markDocumentManagement ?? null,
    }),
    [informe, informeRouteType]
  );

  const disabledFields = React.useMemo(
    () => ({
      ...disabledFields$prop,
      id: true,
      code: true,
      version: true,
      author: true,
      type: true,
      status: true,
      statusDsc: true,
      dtCreated: true,
    }),
    [disabledFields$prop]
  );

  const validationSchema = React.useMemo(
    () =>
      getValidationSchema({
        submitAction: currentAction,
        type: informeRouteType,
      }),
    [currentAction, informeRouteType]
  );
  
  React.useEffect(() => {
    if(!isDataChanged) return;
    const onBeforeUnload = (ev: Event) => {            
      ev.preventDefault();
      // setIsDataChanged(true);
      return (ev.returnValue = false);
    };
    window.addEventListener('beforeunload', onBeforeUnload, {capture:true});
    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload, {capture:true});
    };
  }, [isDataChanged]);

  return (
    <>
      <InformeUpdateForm
        {...props}
        initialValues={initialValues}
        disabledFields={disabledFields}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        onClickSubmit={handleClickSubmit}
        onFormChange={onFormChange}        
      />
      {isConfirmationModalOpen?
        <CreateModal
          isOpen={isConfirmationModalOpen}
          onCloseCreationModal={() => setIsConfirmationModalOpen(false)}
          inputText={modalText}
          typeModal={modalType} 
          action={handleConfirmPublish}
          titleModal={modalTitle}
          loaderType={1}
          loadingModal={loading}
          className={modalClassname}
        />
      :
        null
      }
      {isDataChanged ? 
        <Prompt
        when={true}
        message={`¡ATENCIÓN! Es posible que los cambios no se guarden.`}
      />
        : null
      }
    </>
  );
};

export default InformeUpdateFormContainer;

function useUpdateInforme({ action, handleNavigateToReports, setIsConfirmationModalOpen, setInforme, setModalText, setModalTitle, setModalType, informe_tipo, empresa, setmodalClassname, onFormChange }: { action: SubmitAction, handleNavigateToReports: Function, setIsConfirmationModalOpen: Function, setInforme: Function, setModalText: Function, setModalTitle: Function, setModalType: Function, informe_tipo:InformeRouteType, empresa: ICentroTrabajo|undefined, setmodalClassname:Function, onFormChange: any }) {
  return React.useMemo(
    () => (values: Values, formActions: FormikHelpers<Values>) => {
      console.log("Started submitting");
      formActions.setSubmitting(true);
      console.log(action)

      switch (action) {
        case SubmitAction.SAVE:
          if(onFormChange)
            onFormChange(false);

          OrderService.saveData(values, 18)
          .then((ret)=>{
            console.log("Successfully saved informe: ", ret);
            formActions.setSubmitting(false);
          })
          .catch((err)=>{
            console.error(err);
            formActions.setSubmitting(false);
          });
          // setTimeout(() => {
          //   console.log("Successfully saved informe");
          //   console.log(values);
          //   formActions.setSubmitting(false);
          // }, 2000);
          break;
        case SubmitAction.PUBLISH:
          setInforme(values);
          setmodalClassname("")
          
          if(informe_tipo == InformeRouteType.VISITAS){
            setModalTitle('Confirmar publicación');    
            setModalText(`!ATENCIÓN! ¿Quiere publicar el informe "${values.code}" y generar su PDF?`);
            setModalType(2);
          }   

          if(informe_tipo == InformeRouteType.AUDITORIAS && validationPopUp({values, setModalText, setModalTitle, setModalType})){
            setModalTitle('Confirmar publicación');    
            setModalText(`!ATENCIÓN! ¿Quiere publicar el informe "${values.code}" y generar su PDF?`);
            setModalType(2);
          }          
          setIsConfirmationModalOpen(true);
          
          // values.status = 50;
          // values.dtReport = new Date().toISOString();
          // OrderService.saveData(values, 18)
          // .then((ret)=>{
          //   console.log("Successfully Published informe: ", ret);
            
          //   formActions.setSubmitting(false);
          //   handleNavigateToReports(values.type);
            
          // })
          // .catch((err)=>{
          //   console.error(err);
          //   formActions.setSubmitting(false);
          // });

          setTimeout(() => {
            console.log("Successfully Published informe");
            //console.log(values);
            formActions.setSubmitting(false);
          }, 500);
          break;

        case SubmitAction.PREVIEW:
          if(values.storage && values.storage !== null && [50,97,98,99].includes(values.status)) {
            OpenExternalFile(values.storage);
            formActions.setSubmitting(false);
          }
          else {
            console.log("Started submitting");
            setmodalClassname("dialog-pdf")
            formActions.setSubmitting(true);

            setTimeout(() => {
              setModalTitle('Previsualización');
              setModalType(1);
              setModalText(
                <PDFViewer style={{width:'100%', height:'100%'}}>
                  <MyDocument informe={values} empresa={empresa} />
                </PDFViewer>
              );
              setIsConfirmationModalOpen(true);
              formActions.setSubmitting(false);
              console.log("Successfully Preview informe");

                //   setModalType(1);       
                //   setIsConfirmationModalOpen(true);
    
                //   formActions.setSubmitting(false);
              // const pdfDocument = <MyDocument informe={values} empresa={empresa} />;
              // pdf(pdfDocument).toBlob()
              // .then(data=>{
              //   const url = URL.createObjectURL(data);
              //   console.log(url)
  
              //   setModalTitle('Previsualización');    
              //   // setModalText(<embed src={url}
              //   //   type="application/pdf"/>);
              //   setModalText(<PDFViewer>
              //     <MyDocument informe={values} empresa={empresa} />
              //     </PDFViewer>);
              //   setModalType(1);       
              //   setIsConfirmationModalOpen(true);
  
              //   formActions.setSubmitting(false);
              // })
              // .catch(error=>{
              //   formActions.setSubmitting(false);
              // })
            }, 1000);
          }           
          
          break;
      }
    },
    [action, onFormChange, setInforme, setModalType, informe_tipo, empresa, setModalText, setIsConfirmationModalOpen, setModalTitle, setmodalClassname]
  );
}


// const openPdfInNewWindow = async (empresa:ICentroTrabajo) => {
//   const pdfDocument = <MyDocument informe={informe} empresa={empresa} />; // Utiliza 'informe' directamente desde los props
//   const blob = await pdf(pdfDocument).toBlob();
//   const url = URL.createObjectURL(blob);
//   window.open(url, '_blank', 'noopener,noreferrer');
// };

function validationPopUp({ values, setModalText, setModalTitle, setModalType }: { values: Values; setModalText: Function; setModalTitle: Function; setModalType: Function; }){
  // console.log(values);
  let documental = values.sections!.filter((sub) => sub.groupId === 2);
  let operational = values.sections!.filter((sub) => sub.groupId === 1);
  if(values.markDocumentManagement == null && documental.length > 0){
    setModalTitle('Gestión Documental');    
    setModalText('Debe haber al menos 1 calificación en esta sección');
    setModalType(1);
    return false;
  }
  if(values.markOperationalControl == null && operational.length > 0){
    setModalTitle('Control Operacional');
    setModalText('Debe haber al menos 1 calificación en esta sección');
    setModalType(1);
    return false;
  }
  return true;
}